




























































import { Component, Mixins } from 'vue-property-decorator';
import GroupComponent from '@/mixins/group-component';
import TableDialogFormComponent from '@/mixins/table-dialog-form-component';
import { OrgQueryModel } from '@/entity-model/org-entity';
import { UserEntityModel } from '@/entity-model/user-entity';
import OrgService from '@/service/org';
import { GroupEntityType, ViewModeType } from '@/model/enum';
import OrgTreeComponent from './org-tree.vue';
import OrgUserAddComponent from './org-user-add.vue';

@Component({
    components: {
        'org-tree-component': OrgTreeComponent,
        'org-user-add-component': OrgUserAddComponent
    }
})
export default class OrgListComponent extends Mixins(TableDialogFormComponent, GroupComponent) {
    GroupEntityType = GroupEntityType;
    orgHeadIds = [];
    created() {
        this.initTable({
            service: OrgService,
            queryModel: new OrgQueryModel(),
            tableColumns: UserEntityModel.getTableColumns()
        });
    }

    treeSelect(groupIds: Array<string>) {
        const groupId = _.first(groupIds);
        (this.queryModel as OrgQueryModel).groupIds = groupIds;
        this.$router.push(`${this.$route.path}?groupId=${groupId}`);
        this.service.retrieve(groupId).then((res:any) => {
            this.orgHeadIds = res.userIds;
        });
    }

    get SelectGroupId() {
        return _.first((this.queryModel as OrgQueryModel).groupIds);
    }

    /**
     * 删除操作
     * @param model 数据模型
     */
    async deleteClick(record:any): Promise<boolean> {
        this.startFullScreenLoading('正在删除');
        return OrgService.deleteOrgUsers(this.SelectGroupId, record.id).then((res) => {
            this.removeModel(record);
            this.showMessageSuccess('删除成功');
            return true;
        }).catch((err) => {
            throw err;
        }).finally(() => {
            this.stopFullScreenLoading();
        });
    }

    /**
     * 从列表中移除一个数据
     * @param model 数据模型
     */
    protected removeModel(model: any) {
        const index = this.listData.indexOf(model);
        if (index > -1) {
            this.listData.splice(index, 1);
        }
    }

    detailClick(model) {
        (this.$refs.formDetailDialog as any).dialogOpen(model, this.service, ViewModeType.VIEW);
    }
}

