




























import { Component, Prop } from 'vue-property-decorator';
import TableComponent from '@/mixins/table-component';
import { UserEntityModel, UserQueryModel } from '@/entity-model/user-entity';
import UserService from '@/service/user-v2';
import OrgService from '@/service/org';
import { OrgEntityModel } from '@/entity-model/org-entity';

@Component
export default class OrgUserAddComponent extends TableComponent<UserEntityModel, UserQueryModel> {
    dialogVisible: boolean = false;
    @Prop()
    orgId: string;

    mounted() {
        this.initTable({
            service: new UserService(true, this.orgId),
            queryModel: new UserQueryModel(),
            tableColumns: OrgEntityModel.getTableColumns()
        });
    }

    dialogOpen() {
        this.isAutoQuery = false;
        this.dialogVisible = true;
        (this.service as UserService).orgId = this.orgId;
        this.getList();
        this.isAutoQuery = true;
    }
    dialogOK() {
        if (!this.selectedRowKeys || this.selectedRowKeys.length === 0) {
            this.showMessageWarning('请先选择添加的部门成员');
            return;
        }
        return OrgService.addOrgUsers(this.orgId, this.selectedRowKeys).then((res: any) => {
            this.$emit('dialogOK');
            this.selectedRowKeys = [];
            this.dialogClose();
        });
    }
    dialogClose() {
        this.dialogVisible = false;
    }
}
