var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "org-list-component table-component" },
    [
      _c("div", { staticClass: "page-header" }, [
        _c(
          "div",
          { staticClass: "page-breadcrumb" },
          [
            _c(
              "a-breadcrumb",
              [
                _c(
                  "a-breadcrumb-item",
                  [_c("router-link", { attrs: { to: "/" } }, [_vm._v("首页")])],
                  1
                ),
                _c("a-breadcrumb-item", [_vm._v("组织架构")])
              ],
              1
            )
          ],
          1
        )
      ]),
      _c(
        "div",
        { staticClass: "page-body page-body-margin" },
        [
          _c(
            "a-layout",
            [
              _c(
                "a-layout-sider",
                { staticClass: "page-menu-sider", attrs: { theme: "light" } },
                [
                  _c(
                    "a-card",
                    { attrs: { title: "组织架构", bordered: false } },
                    [
                      _c("org-tree-component", {
                        attrs: { "group-id": _vm.GroupId },
                        on: { select: _vm.treeSelect }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c("a-layout-content", [
                _c(
                  "div",
                  { staticClass: "page-body-margin-left-16" },
                  [
                    _c(
                      "a-card",
                      {
                        staticClass: "table-query-card",
                        attrs: { title: "成员查询" }
                      },
                      [
                        _c("jtl-table-query-component", {
                          ref: _vm.JTL.CONSTANT.DEFAULT_TABLE_QUERY_NAME
                        })
                      ],
                      1
                    ),
                    _c(
                      "a-card",
                      { attrs: { title: "成员列表" } },
                      [
                        _c(
                          "jtl-button",
                          {
                            staticClass: "table-header-button",
                            attrs: { slot: "extra", type: "primary" },
                            on: {
                              click: function($event) {
                                return _vm.addClick()
                              }
                            },
                            slot: "extra"
                          },
                          [_vm._v("+ 添加成员")]
                        ),
                        _c("a-table", {
                          attrs: {
                            loading: _vm.listLoading,
                            columns: _vm.tableColumns,
                            "data-source": _vm.listData,
                            pagination: _vm.Pagination,
                            scroll: _vm.tableScroll,
                            "row-key": "id",
                            size: _vm.TableSize
                          },
                          on: { change: _vm.tableChange },
                          scopedSlots: _vm._u([
                            {
                              key: "name",
                              fn: function(text, record) {
                                return [
                                  _c("span", { attrs: { id: record.id } }, [
                                    _vm._v(_vm._s(record.name))
                                  ]),
                                  _vm._v(" "),
                                  _vm.orgHeadIds.indexOf(record.id) > -1
                                    ? _c("a-icon", {
                                        staticStyle: { color: "blue" },
                                        attrs: { type: "user" }
                                      })
                                    : _vm._e()
                                ]
                              }
                            },
                            {
                              key: "enabled",
                              fn: function(text, record) {
                                return [
                                  _c("span", {
                                    class:
                                      "status-circle circle-" + record.enabled
                                  }),
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        record.enabled ? "启用" : "未启用"
                                      ) +
                                      " "
                                  )
                                ]
                              }
                            },
                            {
                              key: "action",
                              fn: function(text, record) {
                                return [
                                  _c(
                                    "a",
                                    {
                                      on: {
                                        click: function($event) {
                                          return _vm.detailClick(record)
                                        }
                                      }
                                    },
                                    [_vm._v("查看")]
                                  ),
                                  _c("a-divider", {
                                    attrs: { type: "vertical" }
                                  }),
                                  _c(
                                    "a-popconfirm",
                                    {
                                      attrs: {
                                        title: "确认删除?",
                                        "ok-text": "确认",
                                        "cancel-text": "取消"
                                      },
                                      on: {
                                        confirm: function($event) {
                                          return _vm.deleteClick(record)
                                        }
                                      }
                                    },
                                    [
                                      _c("a", { staticClass: "jtl-del-link" }, [
                                        _vm._v("删除")
                                      ])
                                    ]
                                  )
                                ]
                              }
                            }
                          ])
                        })
                      ],
                      1
                    )
                  ],
                  1
                )
              ])
            ],
            1
          )
        ],
        1
      ),
      _c("org-user-add-component", {
        ref: "formDialog",
        attrs: { "org-id": _vm.SelectGroupId },
        on: { dialogOK: _vm.formDialogOK }
      }),
      _c("jtl-entity-dialog", { ref: "formDetailDialog" })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }