

















import { Component } from 'vue-property-decorator';
import FormDialogComponent from '@/mixins/form-dialog-component';
import { OrgEntityModel } from '@/entity-model/org-entity';
import { ViewModeType } from '@/model/enum';
import { ICRUDQ } from '@/model/interface';

@Component
export default class OrgEditComponent extends FormDialogComponent<OrgEntityModel> {
    /**
     * 打开弹窗
     * @param model 数据模型
     * @param service 增删改查服务
     * @param needGetDetail 是否需要调用详情接口
     */
    dialogOpen(model: OrgEntityModel, service: ICRUDQ<OrgEntityModel, any>, viewMode: ViewModeType, needGetDetail: boolean = false): void {
        this.dialogVisible = true;
        this.viewMode = viewMode;
        this.initDialogTitle(this.viewMode);
        if (needGetDetail && service) {
            this.startFullScreenLoading();
            service.retrieve(_.get(model, 'id')).then((res: any) => {
                if (res) {
                    model.userIds = res.userIds;
                }
                this.dialogInit(model, service, viewMode);
            }).catch(err => $log.error(err)).finally(() => {
                this.stopFullScreenLoading();
            });
        } else {
            this.dialogInit(model, service, viewMode);
        }
    }
}
