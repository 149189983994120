var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-modal",
    {
      staticClass: "form-edit-component",
      attrs: { title: "添加部门成员", visible: _vm.dialogVisible },
      on: { cancel: _vm.dialogClose }
    },
    [
      _vm.queryModel
        ? _c(
            "div",
            { staticClass: "org-user-add-component form-edit-component" },
            [
              _c("a-input-search", {
                staticClass: "page-table-search__input",
                attrs: { placeholder: "请输入名称", "allow-clear": "" },
                model: {
                  value: _vm.queryModel.name,
                  callback: function($$v) {
                    _vm.$set(_vm.queryModel, "name", $$v)
                  },
                  expression: "queryModel.name"
                }
              }),
              _c("br"),
              _c("br"),
              _c("a-table", {
                attrs: {
                  loading: _vm.listLoading,
                  columns: _vm.tableColumns,
                  "data-source": _vm.listData,
                  pagination: false,
                  scroll: _vm.tableScroll,
                  "row-key": "id",
                  size: _vm.TableSize,
                  "row-selection": {
                    selectedRowKeys: _vm.selectedRowKeys,
                    onChange: _vm.onSelectChange
                  }
                }
              })
            ],
            1
          )
        : _vm._e(),
      _c(
        "template",
        { slot: "footer" },
        [
          _c(
            "jtl-button",
            { attrs: { "click-prop": _vm.dialogClose.bind(this) } },
            [_vm._v("取消")]
          ),
          _c(
            "jtl-button",
            {
              attrs: { type: "primary", "click-prop": _vm.dialogOK.bind(this) }
            },
            [_vm._v("保存")]
          )
        ],
        1
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }