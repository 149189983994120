

































import { Component, Vue, Prop, Watch, Ref } from 'vue-property-decorator';
import { BaseComponent } from '@/mixins/base-component';
import FormDialogComponent from '@/mixins/form-dialog-component';
import { OrgEntityModel } from '@/entity-model/org-entity';
import { SimpleTreeModel } from '@/model/simple-tree-model';
import OrgService from '@/service/org';
import { ViewModeType } from '@/model/enum';
import OrgEditComponent from './org-edit.vue';

@Component({
    components: {
        'org-edit-dialog': OrgEditComponent
    }
})
export default class OrgTreeComponent extends BaseComponent {
    treeData: Array<OrgEntityModel> = null;
    expandedKeys: Array<string> = null;
    selectedKeys: Array<string> = null;
    @Prop()
    groupId: string;
    treeLoading: boolean = true;

    @Ref()
    private orgFormDialog: FormDialogComponent<OrgEntityModel>;

    mounted() {
        if (this.groupId) {
            this.selectedKeys = [this.groupId];
        }
        this.initTree();
    }

    initTree() {
        this.treeLoading = true;
        OrgService.getOrgTree().then(res => {
            this.treeData = res;
            if (this.groupId) {
                this.expandedKeys = [];
                this.initExpandedKeys(this.treeData);
            } else if (this.treeData && this.treeData.length > 0) {
                // 默认选中第一个节点
                const selectGroupId = this.treeData[0].id;
                this.selectedKeys = [selectGroupId];
                this.$emit('select', this.getSelectGroupIds(this.treeData[0]));
                this.$emit('loaded');
            }
        }).catch(err => {
            $log.error(err);
        }).finally(() => {
            this.treeLoading = false;
        });
    }

    initExpandedKeys(children: Array<SimpleTreeModel>) {
        if (children && children.length > 0) {
            for (let i = 0; i < children.length; i++) {
                if (children[i].id === this.groupId) {
                    this.$emit('select', this.getSelectGroupIds(children[i]));
                    this.$emit('loaded');
                    this.getParentKey(children[i].parent);
                    break;
                } else {
                    this.initExpandedKeys(children[i].children);
                }
            }
        }
    }

    getParentKey(parent: SimpleTreeModel) {
        if (parent) {
            this.expandedKeys.push(parent.id);
            if (parent.parent) {
                this.getParentKey(parent.parent);
            }
        }
    }

    treeSelect(selectedKeys, e:{ selected: boolean, selectedNodes, node, event }) {
        const groupId = e.node.value;
        if (e.selected) {
            this.$emit('select', this.getSelectGroupIds(e.node.dataRef));
        } else {
            this.selectedKeys = [groupId];
        }
    }

    getSelectGroupIds(treeItem: OrgEntityModel | SimpleTreeModel) {
        return _.flatten(this.getChildGroupId(treeItem));
    }

    getChildGroupId(treeItem: OrgEntityModel | SimpleTreeModel) {
        const ids = [];
        if (treeItem) {
            ids.push(treeItem.id);
            if (treeItem.children && treeItem.children.length > 0) {
                ids.push(..._.map(treeItem.children, item => {
                    return this.getChildGroupId(item);
                }));
            }
        }
        return ids;
    }

    addGroupClick(parentId: string, groupNameValidateList: Array<any>) {
        const thingsGroupModel = new OrgEntityModel();
        thingsGroupModel.pId = parentId;
        thingsGroupModel.groupNameValidateList = groupNameValidateList;
        this.orgFormDialog.dialogOpen(thingsGroupModel, OrgService, ViewModeType.NEW);
    }
    editGroupClick(parentId: string, treeItem, groupNameValidateList: Array<any>) {
        treeItem.dataRef.groupNameValidateList = groupNameValidateList;
        treeItem.dataRef.pId = parentId;
        this.orgFormDialog.dialogOpen(treeItem.dataRef, OrgService, ViewModeType.UPDATE, true);
    }
    deleteGroupClick(treeItem) {
        const confirm = this.$confirm({
            title: '确认删除',
            content: `确认删除分组${_.get(treeItem, 'dataRef.title')}`,
            onOk: () => {
                this.startFullScreenLoading('正在删除');
                return OrgService.delete(treeItem).then((ret) => {
                    this.initTree();
                    this.showMessageSuccess('删除成功');
                    return true;
                }).catch((err) => {
                    throw err;
                }).finally(() => {
                    this.stopFullScreenLoading();
                });
            },
            onCancel: () => {
                confirm.destroy();
            }
        });
    }
}

